import { IncentiveKeyStruct } from '../types/NftPosition';

export const IS_TESTNET = window.location.hostname !== 'toolboxtoken.com'

const variables = {
  mainnet: {
    DIYFACTORY_ADDRESS: '0x9e522FBe9bD4BcCfd2Ca9331b298b2D7AA5b8d4c',
    DIYTOKEN_ADDRESS: '0x07aDC37C53CC791C502FfF9314660999EeDd3c20',
    CHARITY_WALLET_ADDRESS: '0x904649dE9123633A131260A0d988eF98E766025a',
    USDC_ADDRESS: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    RATCHET_ADDRESS: '0xB6cf22949b7925E0b08feD0C64e0835079a03C59',
    UNISWAP_V3_STAKER_CONTRACT: '0xe34139463bA50bD61336E0c446Bd8C0867c6fE65',
    UNISWAP_NONFUNGIBLE_POSITION_MANAGER_CONTRACT: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88', // Need to confirm this
    UNISWAP_INCENTIVE_ID: '0xb4399d68ed1ad60f58dcc53c807c200a18093196a0cc67a48ba0374e8d57cd89',
    SOCKET_TOKEN_ADDRESS: '0xf3Bd15eac6d6883967c203dCe06491dD165407CD',
    WETH_TOKEN_ADDRESS: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    // FIXME: TRS - Replace with mainnet incentive data
    // Incentive ID: 0x56820c558f07269a5b4aeea27b287b455c64e90b667c8d7d6bdb4f3487b064fe
    INCENTIVE_TUPLE: ['0xf3Bd15eac6d6883967c203dCe06491dD165407CD','0x8EFB39855aeDBcAB24081467B92A41ef85d6614F',1679288400,1710910800,'0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE'],
    INCENTIVE_KEY: {
      rewardToken: '0xf3Bd15eac6d6883967c203dCe06491dD165407CD',
      pool: '0x8EFB39855aeDBcAB24081467B92A41ef85d6614F',
      startTime: 1679288400,
      endTime: 1710910800,
      refundee: '0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE',
    } as IncentiveKeyStruct
  },
  testnet: {
    DIYFACTORY_ADDRESS: '0xc72555D4961c3a94f4732526Ed76722604e9fd85',
    DIYTOKEN_ADDRESS: '0x8Fb066BB4Cf0b813047B09a053fb7EFb1366AEb6',
    CHARITY_WALLET_ADDRESS: '0x904649dE9123633A131260A0d988eF98E766025a',
    USDC_ADDRESS: '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
    WETH_ADDRESS: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    RATCHET_ADDRESS: '0xc7Fe795561Ce68eAB1ccC52820601326ec909A34',
    UNISWAP_V3_STAKER_CONTRACT: '0xe34139463bA50bD61336E0c446Bd8C0867c6fE65',
    UNISWAP_NONFUNGIBLE_POSITION_MANAGER_CONTRACT: '0xC36442b4a4522E871399CD717aBDD847Ab11FE88',
    UNISWAP_INCENTIVE_ID: '0xb4399d68ed1ad60f58dcc53c807c200a18093196a0cc67a48ba0374e8d57cd89',
    SOCKET_TOKEN_ADDRESS: '0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0',
    WETH_TOKEN_ADDRESS: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
    INCENTIVE_TUPLE: ['0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0','0x91278Ff97E85e8D9A25758446FD4AF138CE68C09',1678679713,1678848042,'0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE'],
    INCENTIVE_KEY: {
      rewardToken: '0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0',
      pool: '0x91278Ff97E85e8D9A25758446FD4AF138CE68C09',
      startTime: 1678679713,
      endTime: 1678848042,
      refundee: '0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE',
    } as IncentiveKeyStruct
  }
}


/**
 * START Incentives
 */

// Goerli USDC/WETH
// const incentiveTuple = ['0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0','0x6337B3caf9C5236c7f3D1694410776119eDaF9FA',1673848800,1705384800,'0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE']
// const incentiveKey: IncentiveKeyStruct = {
//   rewardToken: '0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0',
//   pool: '0x6337B3caf9C5236c7f3D1694410776119eDaF9FA',
//   startTime: 1673848800,
//   endTime: 1705384800,
//   refundee: '0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE',
// }

// Goerli DIY/WETH
// const incentiveTuple = ['0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0','0x91278Ff97E85e8D9A25758446FD4AF138CE68C09',1678679713,1678848042,'0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE']
// export const incentiveKey: IncentiveKeyStruct = {
//   rewardToken: '0x1a8c2fBd2F303EF8F4886584535f9d1d5Fc90ee0',
//   pool: '0x91278Ff97E85e8D9A25758446FD4AF138CE68C09',
//   startTime: 1678679713,
//   endTime: 1678848042,
//   refundee: '0x29521d2FD43a8DB9429FC9F861e07B75aFD363aE',
// }

/**
 * END Incentives
 */

export const envVars = IS_TESTNET ? variables.testnet : variables.mainnet;

import 'twin.macro';
import { useContext } from 'react';
import BlurRings from '../components/BlurRings';

import ConnectWalletModal from '../components/ConnectWalletModal';
import { UserContext } from '../contexts/UserContext';
import { useEagerConnect, useWeb3Listener } from '../hooks';

import StakingSection from './components/StakingSection';
import YourStakingSection from './components/YourStakingSection';
import StakingHeader from './components/StakingHeader';
import StakingFooter from './components/StakingFooter';
import YourLpTokensSection from './components/YourLpTokensSection';

const Staking = () => {
  useEagerConnect();
  useWeb3Listener();

  const { isWalletConnectOpened, setIsWalletConnectOpened } = useContext(UserContext);
  return (
    <>
      <BlurRings />
      <div tw="transition-all duration-200">
        <StakingHeader />
        <StakingFooter />
        <YourLpTokensSection />
        <YourStakingSection />
        <StakingSection />
      </div>
      <ConnectWalletModal
        isOpened={isWalletConnectOpened}
        onClose={() => setIsWalletConnectOpened(false)}
      />
    </>
  );
};

export default Staking;

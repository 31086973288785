import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector'

import { IS_TESTNET } from './env';

const ALCHEMY_API_KEY_GOERLI = 'gq0usLjcZ0djxxYHxAt16SG_KA-oymh-';
const TESTNET_CHAINID = 5;
const TESTNET_RPC =
  `https://eth-goerli.alchemyapi.io/v2/${ALCHEMY_API_KEY_GOERLI}`
const TESTNET_NAME = 'Goerli Test Network';

// const TESTNET_CHAINID = 1337;
// const TESTNET_RPC = 'http://localhost:8545';
// const TESTNET_NAME = 'Hardhat Test Network';

const MAINNET_CHAINID = 1;
const MAINNET_RPC =
  'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161';
const MAINNET_NAME = 'Ethereum Network';

export const NETWORK_CHAINID = IS_TESTNET ? TESTNET_CHAINID : MAINNET_CHAINID;
export const NETWORK_RPC = IS_TESTNET ? TESTNET_RPC : MAINNET_RPC;
export const NETWORK_NAME = IS_TESTNET ? TESTNET_NAME : MAINNET_NAME;

export const SUPPORTED_CHAINIDS = [NETWORK_CHAINID];

export const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAINIDS,
});

export const WALLETCONNECT_CONNECTOR = new WalletConnectConnector({
  bridge: 'https://bridge.walletconnect.org',
  chainId: NETWORK_CHAINID,
  clientMeta: {
    description: 'Algorithmic Liquidity Market Protocol on Avalanche',
    icons: ['https://app.benqi.fi/svgs/qi_black_text.svg'],
    name: 'BENQI',
    url: 'https://app.benqi.fi',
  },
  qrcode: true,
  rpc: {
    [NETWORK_CHAINID]: NETWORK_RPC,
  },
  supportedChainIds: [NETWORK_CHAINID],
});

export const COINBASE_CONNECTOR = new WalletLinkConnector({
  url: NETWORK_RPC,
  appName: 'ToolboxApp',
  supportedChainIds: [NETWORK_CHAINID]
})

export type Connector = 'Injected' | 'WalletConnect' | 'Coinbase';

import 'twin.macro';
import networkImage from '../../assets/svgs/ecosystem/network.svg'
import lineImage from '../../assets/svgs/ecosystem/line.svg'
import tookboxCoinImage from '../../assets/svgs/ecosystem/coin-toolbox.svg'
// import socketCoinImage from '../../assets/svgs/ecosystem/coin-socket.svg'
// import ratchetCoinImage from '../../assets/svgs/ecosystem/coin-ratchet.svg'
import socketCoinImage from '../../assets/images/ecosystem/coin-socket.png'
import ratchetCoinImage from '../../assets/images/ecosystem/coin-ratchet.png'
import { useIsLg } from '../../hooks/useMediaQuery';
import { RATCHET_ADDRESS, SOCKET_TOKEN_ADDRESS } from '../../constants/contracts';

const EcosystemSection = () => {
  const isLarge = useIsLg()
  const copyText = (
    <>
      <p tw='leading-8 text-[#A7B0CA]'>
        We know the struggle (and fun) that it takes to be a DIYer, and we wanted to unite the DIY community through a decentralized Web3.0 ecosystem!
        With Toolbox (DIY), you can earn SOCKET passively, while RATCHET holders gain access to certain exclusive perks within the Toolbox ecosystem!
      </p>
      <p tw='leading-8 text-[#A7B0CA]'>
        Our purpose is to empower the DIY community through cryptocurrency and Web3.0 - giving advice and helping in a decentralized
        manner ensures that no DIY member is left behind!
      </p>
    </>
  )

  const importRatchetInWallet = async () => {
    const { ethereum } = window as any
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: RATCHET_ADDRESS, // The address that the token is at.
          symbol: 'RATCHET', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 0, // The number of decimals in the token
        },
      },
    }).catch(() => false);
  }

  const importSocketInWallet = async () => {
    const { ethereum } = window as any
    await ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: SOCKET_TOKEN_ADDRESS, // The address that the token is at.
          symbol: 'SOCKET', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
        },
      },
    }).catch(() => false);
  }


  const topSection = isLarge
    ? (
      <>
        <div tw='col-span-2'>
          <img src={networkImage} alt='A network represented as boxes connected by edges' />
        </div>
        <div tw='col-span-3 flex flex-col gap-6'>
          <h1 tw='text-5xl font-bold leading-[56px]'>
            The Toolbox ecosystem is here to unite the DIY community, and empower all DIYers!
          </h1>
          {copyText}
        </div>
      </>
    )
    : (
      <>
        <div tw='hidden md:block col-span-1 md:col-span-2'>
          <img src={networkImage} alt='A network represented as boxes connected by edges' />
        </div>
        <div tw='col-span-5 md:col-span-3 flex flex-col'>
          <h1 tw='text-4xl font-bold leading-[48px]'>
            The Toolbox ecosystem is here to unite the DIY community, and empower all DIYers!
          </h1>
        </div>
        <div tw='col-span-5 flex flex-col gap-6'>
          {copyText}
        </div>
      </>
    )

  const divider = (
    <div tw='col-span-5 md:px-[10%] h-1 w-full object-fill'>
      <img
        src={lineImage}
        style={{
          height: '100%',
          width: '100%'
        }}
        alt='Horizontal divider line'
        tw='object-fill'
      />
    </div>
  )

  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
      <div tw='relative w-full mt-4'>
        <div tw='grid grid-cols-5 place-items-center lg:place-items-start bg-[#18273E] shadow-2xl gap-4 gap-y-12 lg:gap-y-20 rounded-lg pt-12 pb-20 lg:py-20 px-10 md:px-12 duration-300 transition-all'>
          {topSection}
          {divider}
          <div tw='grid w-full transition-all justify-items-center col-span-5 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 text-center mt-[-80px]'>
            <div tw='min-w-[250px] max-w-[350px] col-span-1 flex flex-col items-center gap-5'>
              <div tw='mb-[-28%]'>
                <img src={tookboxCoinImage} alt="Toolbox token coin" />
              </div>
              <div>
                <h2 tw='text-2xl'>
                  Toolbox Token
                </h2>
              </div>
              <div tw='h-3/4'>
                <p tw='text-lg text-[#A7B0CA] leading-8'>
                  The token that powers the entire Toolbox ecosystem. Toolbox (DIY) will eventually be used for discounts on certain DIY products from merchants!
                </p>
              </div>
              <a href="#claim" tw="flex items-center w-[206px] py-[14px] justify-center font-bold text-lg align-middle px-[30px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] disabled:shadow-none bg-toolbox-red disabled:bg-background-navy rounded-full" >
                Claim now
              </a>
            </div>
            <div tw='min-w-[250px] max-w-[350px] col-span-1 flex flex-col items-center gap-5'>
              <div tw='mt-[95px] w-[80px]'>
                <img src={ratchetCoinImage} alt="Ratchet token coin" />
              </div>
              <div>
                <h2 tw='text-2xl'>
                  Ratchet Token
                </h2>
              </div>
              <div tw='h-3/4'>
                <p tw='text-lg text-[#A7B0CA] leading-8'>
                  This token is the Exclusive Membership token. All Ratchet holders gain access to many perks and benefits!
                </p>
              </div>
              <button
                onClick={importRatchetInWallet}
                tw="flex items-center w-[206px] py-[14px] justify-center font-bold text-lg align-middle px-[30px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] disabled:shadow-none bg-toolbox-red disabled:bg-background-navy rounded-full"
              >
                Import to Wallet
              </button>
            </div>
            <div tw='min-w-[250px] max-w-[350px] col-span-1 lg:col-span-2 xl:col-span-1 flex flex-col items-center gap-5'>
              <div tw='mt-[95px] w-[80px]'>
                <img src={socketCoinImage} alt="Socket token coin" />
              </div>
              <div>
                <h2 tw='text-2xl'>
                  Socket Token
                </h2>
              </div>
              <div tw='h-3/4'>
                <p tw='text-lg text-[#A7B0CA] leading-8'>
                  This token will be used as the DAO governance token for the Toolbox ecosystem. With Socket, you can vote on what the charity wallet will fund!
                </p>
              </div>
              <button
                onClick={importSocketInWallet}
                tw="flex items-center w-[206px] py-[14px] justify-center font-bold text-lg align-middle px-[30px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] disabled:shadow-none bg-toolbox-red disabled:bg-background-navy rounded-full"
              >
                Import to Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcosystemSection;

import 'twin.macro';

import { Link } from 'react-router-dom';

import iconCoingeckoLogo from '../../assets/svgs/followers/coingecko-logo.svg';
import iconCoinmarketLogo from '../../assets/svgs/followers/coinmarket-logo.svg';
import iconEthereumLogo from '../../assets/svgs/followers/ethereum-logo.svg';
import iconUniswapLogo from '../../assets/svgs/followers/uniswap-logo.svg';

export const followersLink = [
  { icon: iconEthereumLogo, link: '/', title: 'Ethereum Logo' },
  { icon: iconUniswapLogo, link: '/', title: 'Uniswap Logo' },
  { icon: iconCoingeckoLogo, link: '/', title: 'Coingecko Logo' },
  { icon: iconCoinmarketLogo, link: '/', title: 'Coinmarket Logo' },
];

const TrustedBySection = () => {
  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
    <div tw="relative w-full flex flex-col justify-center items-center gap-6 sm:px-12 mt-32 transition-all">
      <div>
        <p tw="px-6 text-2xl">
          Trusted by over 175,000 followers and counting
        </p>
      </div>
      <div>
        <ul tw="flex justify-center gap-4 sm:gap-8">
          {followersLink.map((item) => (
            <li
              key={item.title}
              tw="rounded-full"
            >
              <div tw="w-full h-full flex justify-center items-center uppercase text-white">
                <img
                  alt={item.title}
                  src={item.icon}
                  tw="object-center h-[80px]"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </div>
  );
};

export default TrustedBySection;

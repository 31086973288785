import 'twin.macro';

const StakingHeader = () => {
  return (
    <div id="staking-header" tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] mt-36 leading-[70px]'>
      <div tw="relative justify-center items-center w-full gap-6">
        <div tw='flex flex-col items-center text-center gap-4'>
          <h1 tw='text-[60px] font-bold'>Liquidity Position Tokens</h1>
          <h3 tw='text-2xl text-[#A7B0CA]'>Stake your LP tokens for great benefits</h3>
        </div>
      </div>
    </div>
  );
};

export default StakingHeader;

import 'twin.macro';
import { CHARITY_WALLET_ADDRESS } from '../../constants/contracts';
import copyIcon from '../../assets/svgs/copy.svg'
import { triggerToast } from '../../utils';

const DonateSection = () => {
  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
      <div id="donate" tw="relative flex justify-center items-center w-full">
        <div tw="flex flex-col w-[100vw] md:w-full bg-[#18273E] items-center px-8 md:px-12 lg:px-[140px] xl:px-[240px] py-[76px] lg:py-[100px] gap-6">
          <div tw="text-4xl md:text-5xl font-bold text-center">
            Donate to the charity wallet
          </div>
          <div tw="text-lg text-center leading-8 text-[#A7B0CA]">
            Support our cause and passion for the DIY community! All funds donated will go to a charity chosen by the DIY DAO!
          </div>
          <div tw="flex bg-toolbox-navy max-w-full md:max-w-none lg:w-[650px] items-center gap-6 py-4 px-4 md:py-6 md:px-8 rounded-2xl">
            <div tw="flex-shrink font-mono text-[16px] md:text-[20px] text-[#A7B0CA] overflow-hidden overflow-ellipsis">
              {CHARITY_WALLET_ADDRESS}
            </div>
            <div>
              <button
                tw="font-extrabold w-[48px] h-[48px] md:w-[56px] md:h-[56px] transition-all duration-200 hover:shadow-[0px 0px 30px -8px #ED1C24] bg-toolbox-red rounded-full md:px-4 md:py-2 flex items-center justify-center"
                onClick={() => {
                  navigator.clipboard.writeText(CHARITY_WALLET_ADDRESS)
                  triggerToast('COPIED')
                }}
              >
                <img src={copyIcon} alt="copy" color="blue" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonateSection;

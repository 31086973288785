import 'twin.macro';
import { FC, useState } from 'react';
import _ReactPlayer, { ReactPlayerProps } from 'react-player'
import videoPoster from '../../assets/images/video-poster.png';
import playButtonSvg from '../../assets/svgs/banner/play-button.svg';
import { DIYTOKEN_ADDRESS } from '../../constants/contracts';

const ReactPlayer = _ReactPlayer as unknown as FC<ReactPlayerProps>

const BannerSection = () => {
  const [isVideoPlaying, setVideoPlaying] = useState<boolean>(false);

  const video = (
    <div tw="relative h-0 pt-[56.25%]">
      <ReactPlayer
        light={videoPoster}
        playIcon={
          <img
            alt="play"
            src={playButtonSvg}
            tw="w-[132px] cursor-pointer rounded-full ease duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24]"
            onClick={() => setVideoPlaying(true)}
          />
        }
        controls
        loop
        playing
        style={{
          position: 'absolute',
          top: 0,
          left: 0
        }}
        config={{
          file: {
            attributes: {
              preload: 'auto',
            },
          }
        }}
        url='/videos/banner-video.mp4'
        width="100%"
        height="100%"
      />
    </div>
  )

  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
      <div tw="relative flex flex-wrap items-center w-full mt-[108px] gap-x-[50px] xl:gap-[50px] xl:mt-[164px] lg:gap-y-[80px] transition-all duration-200">
        <div tw="flex items-center justify-center lg:justify-start flex-grow lg:flex-grow-0 lg:flex-shrink w-full lg:w-[25%] sm:min-w-[400px] lg:min-w-[440px] h-[400px]">
          <div tw="flex flex-col items-center justify-start lg:items-start h-[322px] text-5xl sm:text-6xl ">
            <p tw="flex-col text-center sm:text-left tracking-[-0.015rem] font-manrope font-bold leading-[56px] sm:leading-[70px]">
              As simple as <br />grabbing your <br />toolbox
            </p>
            <a
              href={`https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=${DIYTOKEN_ADDRESS}`}
              tw="flex items-center font-bold h-14 align-middle mt-14 px-[30px] py-[10px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] bg-toolbox-red rounded-full text-lg"
            >
              Buy on Uniswap
            </a>
          </div>
        </div>
        <div tw="flex-grow justify-center items-center h-full bg-[#181d2b]">
          {video}
        </div>
      </div>
    </div>
  );
};

export default BannerSection;

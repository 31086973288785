import { useWeb3Provider } from './useWeb3Provider';
import { useState, useEffect } from 'react';
import { CONTRACTS } from '../constants/contracts'
import { INonfungiblePositionManager } from '../types/typechain-types/contracts/interfaces'
import { useContract } from './useContract'
import { convertNftIdToNftPosition, NftPosition } from '../types/NftPosition';
import { envVars } from '../constants/env';

export const useNftPositions = () => {

  const { account: wallet } = useWeb3Provider()

  const nftPositionManagerContract = useContract<INonfungiblePositionManager>(
    CONTRACTS.NonfungiblePositionManager.address,
    CONTRACTS.NonfungiblePositionManager.abi,
    true
  )

  const [nftPositions, setNftPositions] = useState<NftPosition[] | null>(null)
  const [trigger, setTrigger] = useState(0)

  const refresh = () => setTrigger(trigger + 1)

  useEffect(() => {
    if (nftPositionManagerContract == null) { return }
    if (wallet == null) { return }

    const getPositions = async () => {

      const countNfts = await nftPositionManagerContract.balanceOf(wallet)
      const range = Array.from(Array(countNfts.toNumber()).keys())
      const nftIds = await Promise.all(
        range.map(i =>
          nftPositionManagerContract
            .tokenOfOwnerByIndex(wallet, i)
            .then(id => id.toNumber())
        )
      )

      const nftPositions = await Promise.all(
        nftIds.map(nftId =>
          convertNftIdToNftPosition(nftId, nftPositionManagerContract)
        )
      )


      // LP Pools can be identified by the token IDs and the fee
      const tokensAreCorrect = (token0: string, token1: string) =>
        (token0 === envVars.DIYTOKEN_ADDRESS && token1 === envVars.WETH_TOKEN_ADDRESS)
        || (token0 === envVars.WETH_TOKEN_ADDRESS && token1 === envVars.DIYTOKEN_ADDRESS)
      const isCorrectPool = (p: NftPosition) => tokensAreCorrect(p.token0, p.token1) && p.fee === 10000

      // Ensure position is for correct pool and have some liquidity
      const relevantNftPositions =
        nftPositions
          .filter(isCorrectPool)
          .filter(position => !position.liquidity.isZero())

      setNftPositions(relevantNftPositions)

    }

    getPositions()

  }, [nftPositionManagerContract, wallet, trigger])

  return { nftPositions, refresh }
}
import { useState } from 'react';
import ReactModal from 'react-modal';
import { Route, Routes } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout';
import MobileMenu from './components/MobileMenu';
import { UserContextProvider } from './contexts/UserContext';
import Home from './pages/Home';
import Staking from './pages/Staking';
import Assets from './pages/Assets';

// ReactModal.setAppElement('#root');

const App = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <>
    <UserContextProvider>
      <ParallaxProvider>
        <Layout menuOpened={isMenuOpened} onToggleMenu={toggleMenu}>
          <Routes>
            <Route element={<Home />} path="/" />
            <Route element={<Staking />} path="/staking" />
            <Route element={<Assets />} path='/assets' />
          </Routes>
          {isMenuOpened && (
            <MobileMenu
              onClose={() => {
                console.log('Setting menu to opened = false')
                setIsMenuOpened(false)
              }}
            />
          )}
        </Layout>
      </ParallaxProvider>
    </UserContextProvider>

    <ToastContainer />
    </>
  );
};

export default App;

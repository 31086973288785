import 'twin.macro';

import step1Img from '../../assets/svgs/quickstart/step-1.svg';
import step2Img from '../../assets/svgs/quickstart/step-2.svg';
import step3Img from '../../assets/svgs/quickstart/step-3.svg';
import step4Img from '../../assets/svgs/quickstart/step-4.svg';

const steps = [
  { desc: 'List how much DIY you would like to buy', icon: step1Img, alt: 'A Toolbox coin' },
  { desc: 'Connect your wallet', icon: step2Img, alt: 'A wallet' },
  { desc: 'Approve transaction in defi wallet', icon: step3Img, alt: 'Document representing a transaction' },
  { desc: 'Receive DIY in your wallet!', icon: step4Img, alt: 'A wallet with DIY token in it' },
];


const QuickStartSection = () => {
  return (
    <div id="guide" tw='relative px-[18px] sm:px-[50px] lg:px-[8%]' css={{ scrollMarginTop: '100px' }}>
      <div tw="flex flex-col items-center gap-[70px] w-full">
          <h2 tw="w-full font-bold text-5xl leading-[56px] text-center">
            Quick Start Guide
          </h2>
          <div tw="flex flex-wrap justify-center w-full gap-8">
            {
              steps.map(({ alt, desc, icon }, index) => (
                <div key={desc} tw="flex flex-col justify-center items-center px-8 bg-toolbox-navy w-[325px] h-[308px] rounded-3xl">
                  <div tw="flex justify-center items-center mt-[-86px]">
                    <img src={icon} alt={alt} />
                  </div>
                  <p tw="text-2xl font-bold mt-[-68px] mb-[10px]">{`Step ${index + 1}`}</p>
                  <p tw="text-lg text-center text-[#A7B0CA]">{desc}</p>
                </div>
              ))
            }
          </div>
      </div>
    </div>
  );
};

export default QuickStartSection;

import 'twin.macro';

import ropeImage from '../../assets/svgs/ratchet/rope.svg'
import wrenchImage from '../../assets/svgs/ratchet/wrench.svg'
import crownImage from '../../assets/svgs/ratchet/crown.svg'
import CurrencyInput from '../../components/CurrencyInput';
import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useContract, useERC20Approve, useWeb3Provider } from '../../hooks';
import { RatchetToken } from '../../types/typechain-types';
import { RatchetTokenV2 } from '../../types/typechain-types/contracts/RatchetTokenV2';
import { RATCHET_ADDRESS } from '../../constants/contracts';
import { RATCHET_PRICE_IN_USDC } from '../../constants/ratchet';
import RATCHET_ABI from '../../constants/ABI/Ratchet.json'
import { useMachine } from '@xstate/react';
import { buyRatchetMachine } from '../../utils/buy-ratchet-machine';
import { useNumericInput } from '../../hooks/useNumericInput';
import { useTokenBalance } from '../../hooks/useTokenBalance';
import CircularProgress from '@mui/material/CircularProgress';

const RatchetSection = () => {

  const inputRef = useRef<HTMLInputElement>(null)
  const { cursorIndex, handleChange, value: ratchetAmount } = useNumericInput(inputRef)

  const { account } = useWeb3Provider();

  const ratchet = useContract<RatchetTokenV2>(RATCHET_ADDRESS, RATCHET_ABI, true)

  const { balance: ratchetBalance, refresh: refreshRatchetBalance } = useTokenBalance(ratchet, account)

  const approveTransactionApproved = () => {
    console.log('APPROVE_APPROVED_IN_METAMASK')
    send('APPROVE_APPROVED_IN_METAMASK')
  }

  const { approve } = useERC20Approve('USDC', RATCHET_ADDRESS, approveTransactionApproved)

  const approveInMetamask = () => {
    const quantityToApprove = +(ratchetAmount.replace(/,/g, ''))
    return approve(quantityToApprove * RATCHET_PRICE_IN_USDC)
      .then(() => {
        console.log('APPROVE_COMPLETE')
        send('APPROVE_COMPLETE')
      })
      .catch(() => {
        console.log('ERROR')
        send('ERROR')
      })

  }
  const approveError = () => {
    console.error('There was a problem approving the USDC')
  }

  const purchaseError = () => {
    console.error('There was a problem purchasing the Ratchet tokens')
  }

  const purchaseInMetamask = async () => {
    if (ratchet == null) {
      console.log('ERROR')
      send('ERROR')
      return
    }
    try {
      const quantityToBuy = +(ratchetAmount.replace(/,/g, ''))
      console.log(`Buying ratchet: ${quantityToBuy}`)
      const transaction = await ratchet.buyTokens(quantityToBuy)
      console.log('PURCHASE_APPROVED_IN_METAMASK')
      send('PURCHASE_APPROVED_IN_METAMASK')
      await transaction.wait()
      console.log('PURCHASE_COMPLETE')
      send('PURCHASE_COMPLETE')
      refreshRatchetBalance()
    } catch (e: any) {
      console.error('There was an issue buying Ratchet')
      send('ERROR')
    }
  }

  const [state, send] = useMachine(buyRatchetMachine, {
    actions: {
      approveInMetamask,
      purchaseInMetamask,
      approveError,
      purchaseError,
      // Not sure why the compiler is requiring this randome function, seems like a bug with xstate generator
      // actions2: () => { }
    }
  })

  useEffect(() => {
    console.log(`STATE: ${state.value}`)
  }, [state])

  const buyRatchetClicked = () => {
    console.log('APPROVE_CLICKED')
    send('APPROVE_CLICKED')
  }

  return (
    <div tw='relative z-10 px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
      <div tw='relative w-full mt-28 lg:mt-52'>
        <div tw='flex justify-center mb-12 lg:mb-20'>
          <h1 tw='text-4xl lg:text-5xl max-w-[550px] md:max-w-full font-bold text-center'>
            Thanks to the Ratchet token, you will
          </h1>
        </div>
        <div tw='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'>
          <div tw='flex flex-col justify-around items-center px-12 pt-6 pb-12 bg-toolbox-navy rounded-2xl'>
            <div>
              <img src={ropeImage} alt="" />
            </div>
            <div tw='text-2xl text-center mt-[-24px]'>
              Have
              <span tw='text-[#3185FC]'>
                &nbsp;
                early access
                &nbsp;
              </span>
              to our DIY staking feature
            </div>
          </div>
          <div tw='flex flex-col justify-around items-center px-12 pt-6 pb-12 bg-toolbox-navy rounded-2xl'>
            <div>
              <img src={wrenchImage} alt="" />
            </div>
            <div tw='text-2xl text-center mt-[-24px]'>
              Have access to some of our
              <span tw='text-[#3185FC]'>
                &nbsp;
                exclusive DIY content
                &nbsp;
              </span>
            </div>
          </div>
          <div tw='flex justify-center col-span-1 md:col-span-2 xl:col-span-1 '>
            <div tw='flex flex-col justify-center items-center w-auto md:w-1/2 xl:w-auto px-12 pt-6 pb-12 bg-toolbox-navy rounded-2xl'>
              <div>
                <img src={crownImage} alt="" />
              </div>
              <div tw='text-2xl text-center mt-[-24px]'>
                <span tw='text-[#3185FC]'>
                  &nbsp;
                  Join a VIP community
                  &nbsp;
                </span>
                of DIYers, gaining access to the exclusive Discord chat
              </div>
            </div>
          </div>
        </div>
        <div tw='flex justify-center mt-6'>
          <div tw='flex flex-col items-center w-[700px] py-10 px-10 md:px-20 gap-8 bg-toolbox-navy rounded-2xl'>
            <div tw='text-4xl font-extrabold text-center'>
              Purchase Ratchet Tokens
            </div>
            <div tw='flex flex-col items-center'>
              <div tw='w-full text-[18px]'>
                Current price: 36 USDC
              </div>
              <div tw='w-full text-[18px]'>
                Your balance: {ratchetBalance} tokens
              </div>
            </div>
            {['approving', 'approveFinalizing', 'purchasing', 'purchaseFinalizing', 'error', 'done'].some(state.matches) ? (
              <div tw='flex flex-row justify-center items-center w-full p-6 bg-[#1A273E] min-h-[116px] rounded-2xl'>
                <div tw='text-xl'>
                  <div tw='flex flex-wrap justify-center items-center gap-8'>
                    {state.matches('approving') && 'Step 1 of 2: Please approve the USDC in Metamask'}
                    {state.matches('approveFinalizing') && <>Step 1 of 2: USDC transaction in progress... <CircularProgress /></>}
                    {state.matches('purchasing') && 'Step 2 of 2: Please approve the purchase in Metamask'}
                    {state.matches('purchaseFinalizing') && <>Step 2 of 2: Purchase transaction in progress... <CircularProgress /></>}
                    {state.matches('error') && 'There was an error, please try again'}
                    {state.matches('done') && `Purchase complete! You now own ${ratchetBalance} Ratchet tokens`}
                  </div>
                </div>
              </div>
            ) : (
              <div tw='flex flex-row flex-wrap items-center justify-center gap-x-8 gap-y-6 py-4 w-full pl-[34px] pr-6 bg-[#1A273E] min-h-[116px] rounded-2xl'>
                <div tw='flex-grow'>
                  <input
                    type="text"
                    value={ratchetAmount}
                    onChange={handleChange}
                    onFocus={e => e.target.selectionStart = cursorIndex}
                    ref={inputRef}
                    placeholder='Enter Ratchet amount'
                    tw='appearance-none bg-transparent py-2 w-full text-center text-xl border-b focus:border-b focus:outline-none border-[#A7B0CA] focus:border-white focus:mb-[-1px] text-[#A7B0CA]'
                  />
                </div>
                <div tw='flex-shrink'>
                  <button
                    tw="h-14 font-extrabold text-lg transition-all ease duration-200 hover:shadow-[0px 0px 30px -10px #ED1C24] bg-toolbox-red disabled:bg-gray-300 disabled:cursor-not-allowed disabled:shadow-none rounded-full px-4 py-2 w-[190px] flex items-center justify-center gap-2"
                    disabled={ratchetAmount === ''}
                    onClick={buyRatchetClicked}
                  >
                    Buy now
                  </button>
                </div>

              </div>
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

      export default RatchetSection;

import 'twin.macro';

import { FC, useContext, useState } from 'react';

import ChevronDownImg from '../assets/images/chevron-down.svg';
import { UserContext } from '../contexts/UserContext';
import { useWeb3Provider } from '../hooks';
import { getWalletAddressAbbr } from '../utils';

type ConnectWalletButtonProps = {
  onPress?: Function
}

const ConnectWalletButton: FC<ConnectWalletButtonProps> = ({ onPress }) => {
  const { isWalletConnectOpened, setIsWalletConnectOpened } =
    useContext(UserContext);
  const { account, active, deactivate } = useWeb3Provider();
  const [isWalletInfoOpened, setIsWalletInfoOpened] = useState(false);

  return (
    <div tw="relative h-[48px]">
      <button
        tw="h-full font-bold transition-all ease duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] bg-toolbox-red rounded-full px-4 py-2 w-[190px] flex items-center justify-center gap-2"
        onClick={() => {
          console.log(`onPress: ${onPress}`)
          onPress?.()
          if (active) {
            setIsWalletInfoOpened(!isWalletInfoOpened);
          } else {
            !isWalletConnectOpened
              ? setIsWalletConnectOpened(true)
              : setIsWalletConnectOpened(false);
          }
        }}
      >
        {active ? (
          <>
            <span>{getWalletAddressAbbr(account)}</span>
            <img alt="" src={ChevronDownImg} />
          </>
        ) : (
          'Connect Wallet'
        )}
      </button>

      {active && isWalletInfoOpened ? (
        <button
          tw="absolute text-white bg-red-500 rounded-3xl px-4 py-2 mt-2 w-[190px] flex items-center justify-center z-10"
          onClick={() => {
            deactivate();
            setIsWalletInfoOpened(false);
          }}
        >
          Disconnect
        </button>
      ) : null}
    </div>
  );
};

export default ConnectWalletButton;

import 'twin.macro';
import { useContext } from 'react';
import BlurRings from '../components/BlurRings';

import ConnectWalletModal from '../components/ConnectWalletModal';
import { UserContext } from '../contexts/UserContext';
import { useEagerConnect, useWeb3Listener } from '../hooks';
import BannerSection from './components/BannerSection';
import QuickStartSection from './components/QuickStartSection';
import RoadMapSection from './components/RoadMapSection';
import TheStorySection from './components/TheStorySection';
import TrustedBySection from './components/TrustedBySection';
import DonateSection from './components/DonateSection';
import WhitepaperSection from './components/WhitepaperSection';
import EcosystemSection from './components/EcosystemSection';
import RatchetSection from './components/RatchetSection';
import ToolboxSection from './components/ToolboxSection';
import StakingSection from './components/StakingSection';
import DaoSection from './components/DaoSection';

const Home = () => {
  useEagerConnect();
  useWeb3Listener();

  const { isWalletConnectOpened, setIsWalletConnectOpened } = useContext(UserContext);
  return (
    <>
      <BlurRings />
      {/* <div tw="px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] transition-all duration-200"> */}
      <div tw="transition-all duration-200">
        <BannerSection />
        <TrustedBySection />
        <WhitepaperSection />
        <EcosystemSection />
        <RatchetSection />
        <ToolboxSection />
        {/* <DaoSection /> */}
        <TheStorySection />
        <QuickStartSection />
        <RoadMapSection />
        <DonateSection />
      </div>
      <ConnectWalletModal
        isOpened={isWalletConnectOpened}
        onClose={() => setIsWalletConnectOpened(false)}
      />
    </>
  );
};

export default Home;

import { useWeb3Provider } from './useWeb3Provider';
import { useEffect, useState } from 'react';
import { CONTRACTS } from '../constants/contracts'
import { IUniswapV3Staker } from '../types/typechain-types/contracts/interfaces'
import { useContract } from './useContract'
import { envVars } from '../constants/env';
import { BigNumber } from 'ethers';

export const useStaker = () => {

  const { account: wallet } = useWeb3Provider()
  const [trigger, setTrigger] = useState(0)
  const [rewardsOwed, setRewardsOwed] = useState(BigNumber.from(0))

  const refresh = () => setTrigger(trigger + 1)

  const stakerContract = useContract<IUniswapV3Staker>(
    CONTRACTS.UniswapV3Staker.address,
    CONTRACTS.UniswapV3Staker.abi,
    true
  )

  useEffect(() => {
    if (stakerContract == null) { return }
    if (wallet == null) { return }

    const getStakes = async () => {
      const rewards = await stakerContract.rewards(envVars.SOCKET_TOKEN_ADDRESS, wallet)
      setRewardsOwed(rewards)
    }
    getStakes()
  }, [stakerContract, wallet, trigger])

  const claimRewards = async () => {
    if (stakerContract == null) { return }
    if (wallet == null) { return }
    const result = await stakerContract.claimReward(envVars.SOCKET_TOKEN_ADDRESS, wallet, 0)
    await result.wait()
  }

  return { rewardsOwed, refresh, claimRewards }
}
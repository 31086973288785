import 'twin.macro';

const StakingSection = () => {
  const programDurationDays = '365'
  const apyEndDate = 'March 19, 2024'
  return (
    <div id="staking-stats" tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] mt-32'>
      <div tw="relative flex justify-center items-center w-full">
        <div tw="flex flex-col w-[100vw] md:w-full bg-[#18273E] items-center px-8 md:px-12 lg:px-[140px] xl:px-[240px] py-[76px] lg:py-[100px] gap-6 rounded-2xl">
          <div tw="text-4xl md:text-5xl font-bold text-center">
            LP Tokens Staking
          </div>
          <div tw="text-lg text-center leading-8 text-[#A7B0CA]">
            Deposit your Liquidity Pool tokens to earn extra rewards
          </div>
          <div tw='grid grid-cols-1 gap-8 md:grid-cols-2 w-full text-center'>
            <div tw="flex bg-toolbox-navy justify-center items-center gap-6 py-4 px-4 md:py-6 md:px-8 rounded-2xl">
              <div tw="flex-shrink font-semibold text-[#A7B0CA] overflow-hidden overflow-ellipsis">
                <p tw='text-white text-[24px] '>
                  {programDurationDays} days
                </p>
                <p tw='text-lg'>
                  Program duration
                </p>
              </div>
            </div>
            <div tw="flex bg-toolbox-navy justify-center items-center gap-6 py-4 px-4 md:py-6 md:px-8 rounded-2xl">
              <div tw="flex-shrink font-semibold text-[#A7B0CA] overflow-hidden overflow-ellipsis">
                <p tw='text-white text-[24px] '>
                  {apyEndDate}
                </p>
                <p tw='text-lg'>
                  Last day to earn APY
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default StakingSection;

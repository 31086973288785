import { BaseContract, Contract } from '@ethersproject/contracts';
import { useMemo } from 'react';

import { getNetworkLibrary } from '../utils';
import { useNetworkConnector } from './useNetworkConnector';
import { useWeb3Provider } from './useWeb3Provider';

export const useContract = <C extends BaseContract = Contract>(
  address?: string,
  abi?: any,
  withSigner?: boolean
) => {
  const { account, library } = useWeb3Provider();
  const network = useNetworkConnector();

  return useMemo(() => {
    if (!address) { return null }

    const networkLibrary = getNetworkLibrary(network);
    const signer =
      withSigner && account
        ? library?.getSigner(account)
        : networkLibrary
          ? networkLibrary
          : library
    console.log(`Contract/signer will be: ${address}/${account}`)
    return new Contract(
      address,
      abi,
      signer
    ) as C;
  }, [abi, account, address, library, network, withSigner]);
};

import DIYToken_ABI from './ABI/DIYToken.json';
import RATCHET_ABI from './ABI/Ratchet.json';
import USDC_ABI from './ABI/usdc.json';
import UNISWAP_V3_STAKER_ABI from './ABI/IUniswapV3Staker.json'
import UNISWAP_NONFUNGIBLE_POSITION_MANAGER_ABI from './ABI/INonfungiblePositionManager.json'
import { envVars } from './env';

export const {
  CHARITY_WALLET_ADDRESS,
  DIYFACTORY_ADDRESS,
  DIYTOKEN_ADDRESS,
  RATCHET_ADDRESS,
  SOCKET_TOKEN_ADDRESS,
  UNISWAP_NONFUNGIBLE_POSITION_MANAGER_CONTRACT,
  UNISWAP_V3_STAKER_CONTRACT,
  USDC_ADDRESS,
} = envVars

export const ETHCOIN_DECIMALS = 18;
export const DIYTOKEN_DECIMALS = 18;
export const USDC_DECIMALS = 6;
export const RATCHET_DECIMALS = 18;

export type Contract = 'UniswapV3Staker' | 'NonfungiblePositionManager'
export const CONTRACTS: {
  [key in Contract]: {
    address: string,
    abi: any
  }
} = {
  UniswapV3Staker: {
    address: UNISWAP_V3_STAKER_CONTRACT,
    abi: UNISWAP_V3_STAKER_ABI,
  },
  NonfungiblePositionManager: {
    address: UNISWAP_NONFUNGIBLE_POSITION_MANAGER_CONTRACT,
    abi: UNISWAP_NONFUNGIBLE_POSITION_MANAGER_ABI,
  }
}

export type Asset = 'ETH' | 'DIY' | 'USDC' | 'Ratchet';

export const ASSET_LIST: {
  [key in Asset]: {
    address: string;
    abi: any;
    decimals: number;
  };
} = {
  DIY: {
    abi: DIYToken_ABI,
    address: DIYTOKEN_ADDRESS,
    decimals: DIYTOKEN_DECIMALS,
  },
  ETH: {
    abi: null,
    address: '',
    decimals: ETHCOIN_DECIMALS,
  },
  USDC: {
    abi: USDC_ABI,
    address: USDC_ADDRESS,
    decimals: USDC_DECIMALS
  },
  Ratchet: {
    abi: RATCHET_ABI,
    address: RATCHET_ADDRESS,
    decimals: RATCHET_DECIMALS
  }
};

import { ChangeEvent, RefObject, useEffect, useState } from 'react'


export const useNumericInput = (inputRef: RefObject<HTMLInputElement>) => {

  const [value, setValue] = useState('')
  const [cursorIndex, setCursorIndex] = useState(0)

  const addCommas = (num: number) => num.toLocaleString()
  const removeNonNumeric = (num: string) => +num.toString().replace(/[^0-9]/g, '')

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const res = addCommas(removeNonNumeric(event.target.value))
    const lenBefore = event.target.value.length
    const lenAfter = res.length
    if (lenAfter === lenBefore) {
      setCursorIndex(event.target.selectionStart!)
    } else if (lenAfter > lenBefore) {
      setCursorIndex(event.target.selectionStart! + 1)
    } else {
      setCursorIndex(event.target.selectionStart! - 1)
    }
    setValue(res == '0' ? '' : res)
  }

  useEffect(() => {
    const input = inputRef.current
    if (input != null) {
      input.setSelectionRange(cursorIndex, cursorIndex)
    }
  }, [inputRef, cursorIndex])

  return { value, handleChange, cursorIndex }

}
import 'twin.macro';

import { Link } from 'react-router-dom';

import story3Img from '../../assets/images/story-3.png';
import story1Img from '../../assets/images/story-11.png';
import story2Img from '../../assets/images/story-2.png';
import likeSvg from '../../assets/svgs/thestory/like.svg';
import quoteMarkSvg from '../../assets/svgs/thestory/quote-mark.svg';

const TheStorySection = () => {
  return (
    <div id="about" tw='relative px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]' css={{ scrollMarginTop: '80px' }}>
    <div tw="w-full px-4 pt-32 pb-20 grid grid-cols-1 md:grid-cols-2 gap-8">
      <div tw="relative">
        <div tw="flex justify-center items-center gap-4">
          <div tw="flex flex-col items-end self-center relative">
              <div tw='flex items-center justify-end'>
                <div tw="rounded-2xl p-4 shadow-lg text-right">
                  <p tw="font-extrabold pb-2">Rogelio</p>
                  <p tw="text-xs">Thank you to the moon & back!</p>
                </div>
                <div tw="bg-[#ED1C24] rounded-full z-20">
                  <div tw='flex justify-center items-center w-[40px] h-[40px] '>
                    <img alt={''} src={quoteMarkSvg} tw="w-[25px]" />
                  </div>
                </div>
              </div>
              <img alt={''} src={story1Img} tw="w-[300px]" />
              <div tw='flex items-center'>
                <div tw="bg-[#ED1C24] rounded-full z-20">
                  <div tw='flex justify-center items-center w-[40px] h-[40px] '>
                    <img alt={''} src={quoteMarkSvg} tw="w-[25px]" />
                  </div>
                </div>
                <div tw="rounded-2xl p-4 shadow-lg z-10">
                  <p tw="font-extrabold pb-2">Mark</p>
                  <p tw="text-xs">Thank you! Fast and easy to understand and right to the point.</p>
                </div>
              </div>
          </div>
          <div tw="relative flex flex-col gap-4">
            <img alt={''} src={story2Img} tw="w-[250px]"/>
            <img alt={''} src={story3Img} tw="w-[250px]"/>
          </div>
        </div>
      </div>
      <div tw="flex flex-col gap-4 p-10 max-w-prose">
        <h2 tw="font-extrabold text-5xl mt-10 md:mt-0">The story</h2>
        <p tw="text-lg">My name is Mike Borders, my wife Alena and I began our YouTube channel over <span tw="font-bold">10 years</span> ago. As young adults trying to save money, we realized how important it was to maintain our belongings. Our passion is creating videos that equip you with the knowledge and confidence to get your DIY projects done.</p>
        <p tw="text-lg">Very quickly, we discovered how many DIYers out there were just like us, interested and motivated to learn. With over <span tw="font-bold">1,200 DIY projects</span> completed to date, we have a lot more to do and are excited to continue our mission.</p>
        <p tw="text-lg">Along with filming DIY projects, answering your questions is our top priority. The DIY community is the friendliest, most loyal, and most interactive community we have ever been a part of. The roots of Toolbox token are based on our philosophy that "We DIYers need to stick together!".</p>
        <a href="#guide" tw="mt-8 px-[30px] py-[12px] w-[fit-content] bg-red-500 text-gray-50 text-xl rounded-full">Let’s get started</a>
      </div>
    </div>
    </div>
  );
};

export default TheStorySection;

import { BigNumber } from 'ethers';
import { useCallback, useEffect, useState } from 'react';

import { Asset, ASSET_LIST } from '../constants/contracts';
import { ERC20 } from '../types/typechain-types';
import { triggerToast } from '../utils';
import { useContract } from './useContract';
import { useIsMounted } from './useIsMounted';
import { useWeb3Provider } from './useWeb3Provider';

export const useERC20Approve = (asset: Asset, destination: string, transactionApproved?: Function) => {
  const erc20Contract = useContract<ERC20>(
    ASSET_LIST[asset].address,
    ASSET_LIST[asset].abi,
    true
  );
  const { account } = useWeb3Provider();
  const [isApproved, setIsApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const isMounted = useIsMounted();

  const approve = useCallback(async (amount: number) => {
    if (account == null) {
      console.error('Wallet not connected')
      return
    }
    if (erc20Contract == null) {
      console.error('Contract was null')
      return
    }

    // If we already have enough allowance, no need to approve
    const currentAllowance = await erc20Contract.allowance(account, destination)
    if (currentAllowance.toNumber() >= amount) {
      setIsApproved(true)
      triggerToast('SUCCESS')
      return
    }

    // Else we need to approve
    try {
      setIsApproving(true);
      const transaction = await erc20Contract.approve(destination, amount);
      transactionApproved?.()
      await transaction.wait()
      if (isMounted.current) {
        setIsApproved(true);
        triggerToast('SUCCESS');
      }
    } catch (e: any) {
      triggerToast('ERROR');
      throw e
    } finally {
      if (isMounted.current) {
        setIsApproving(false);
      }
    }
  }, [erc20Contract, isMounted, destination]);

  useEffect(() => {
    if (account && erc20Contract) {
      erc20Contract
        .allowance(account, destination)
        .then((value: BigNumber) => {
          if (!value.isZero() && isMounted.current) {
            setIsApproved(true);
          } else {
            setIsApproved(false);
          }
        });
    }
  }, [account, erc20Contract, isMounted, destination]);

  return {
    approve,
    isApproved,
    isApproving,
  };
};

import 'twin.macro';

import coinsImage from '../../assets/svgs/toolbox/coins.svg'
import bulletImage from '../../assets/svgs/toolbox/bullet.svg'
import infoImage from '../../assets/svgs/toolbox/info.svg'
import { useState } from 'react';

const ToolboxSection = () => {
  const [hover, setHover] = useState(false)
  const lines = [
    'Toolbox (DIY) is the base token of the ecosystem',
    '1% tax for each Toolbox transaction ensures a solid upkeep of the ecosystem while giving back to the community through the charity wallet',
    'Ratchet token is the exclusive ecosystem membership token',
    'Socket is the governance token of the DIY DAO',
    'Socket\'s 1% tax helps maintain a good upkeep of the Toolbox ecosystem as well',
  ]
  return (
    <div tw='relative'>
      <div tw='w-full mt-[-85px] bg-[#18273E] bg-opacity-100 pt-[180px] xl:pt-[270px] lg:pb-[110px]'>
        <div tw='flex flex-wrap justify-center lg:justify-start w-screen h-full relative px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
          <div tw='w-full lg:w-[700px] xl:w-[750px] px-2 md:px-11 '>
            <div tw='flex flex-col'>
              <div tw='flex mb-[59px] items-center'>
                <div tw='flex-shrink max-w-[80%] mr-6'>
                  <h1 tw='text-left text-4xl xl:text-5xl font-bold'>
                    About Toolbox Tokens
                  </h1>
                </div>
                <div tw='relative w-[42px] h-[42px]'>
                  <div
                    tw='flex justify-center items-center w-full h-full bg-toolbox-navy hover:bg-[#1a314f] rounded-[8px] cursor-help'
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <img
                      src={infoImage}
                      width='24px'
                      height='24px'
                      alt="DIY tax info"
                      tw='absolute'
                    />
                  </div>
                  <div
                    tw='absolute flex justify-center items-center w-[200px] left-[-120px] top-[-120px] bg-toolbox-navy rounded-lg shadow-[0px 13px 30px rgba(0, 0, 0, 0.25)] ease duration-200'
                    css={{
                      opacity: hover ? 1 : 0
                    }}
                  >
                    <div tw='text-xs text-[#A7B0CA] leading-[18px] m-4'>
                      DIY transactions are subject to a 1% tax consisting of 0.8% for our marketing wallet and 0.2% for the charity wallet.
                    </div>
                  </div>
                </div>
              </div>
              <ul tw='text-lg ml-[-6px] text-[#A7B0CA]'>
                {
                  lines.map((line) => (
                    <li tw='flex items-start' key={line}>
                      <img src={bulletImage} alt="bullet-point" tw='mt-[4px]' />
                      <p tw='ml-[6px] leading-8'>
                        {line}
                      </p>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div tw='flex justify-center lg:justify-end w-full lg:w-auto mb-[-80px]'>
            <div tw='static lg:absolute w-[820px] md:w-[min(820px, calc(100vw - 350px))] top-auto lg:top-[-200px] xl:top-[-270px] lg:right-[-175px] 3xl:right-[150px] '>
              <img src={coinsImage} alt="" tw='block' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolboxSection;

import 'twin.macro';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

const BlurRings: FC = () => {

  const numRings = 3
  const offsetY = -384
  const offsetXOdd = -491
  const colorEven = '#ED1C24'
  const colorOdd = '#662C91'

  return (
    <div tw="absolute w-screen" css={{ top: `${offsetY}px` }} >
      {Array(numRings).fill(null).map((_, i) =>
        i % 2 === 0
          ? (
            <div
              css={{ border: `55px solid ${colorEven}` }}
              tw="blur-[50px] w-[1148px] h-[1148px] ml-[50%] mb-[830px] last:mb-0 rounded-full mix-blend-normal"
              key={i}
            />
          )
          : (
            <div
              css={{ marginLeft: `${offsetXOdd}px`, border: `55px solid ${colorOdd}` }}
              tw="blur-[50px] w-[1148px] h-[1148px] mb-[830px] last:mb-0 rounded-full mix-blend-normal"
              key={i}
            />
          )
      )}
    </div>
  )
}

export default BlurRings

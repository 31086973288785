import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom';
import tw from 'twin.macro';

import iconLiMarkSvg from '../../assets/svgs/roadmap/icon-li-mark.svg';
import checkImage from '../../assets/svgs/roadmap/check.svg'
import step3Image from '../../assets/svgs/roadmap/step-3.svg'
import step4Image from '../../assets/svgs/roadmap/step-4.svg'

const phases = [
  {
    iconImg: checkImage,
    bullets: ['Token Launch (DIY)', 'Presale event', 'Etherscan contract verification'],
    title: 'Phase 1',
    complete: true
  },{
    iconImg: checkImage,
    bullets: ['List DIY on a Decentralized exchange', 'Charity wallet creation'],
    title: 'Phase 2',
    complete: true
  },{
    iconImg: step3Image,
    bullets: ['Ratchet membership token live', 'Socket token introduced', 'DIY DAO live', 'LP staking rewards go live'],
    title: 'Phase 3',
    complete: false
  },{
    iconImg: step4Image,
    bullets: ['10,000 DIYers', 'Listing Toolbox on more exchanges', 'Additional partnership announcements'],
    title: 'Phase 4',
    complete: false
  }
]

const RoadMapSection = () => {
  const useIsLargeMediaQuery = () => useMediaQuery({ query: '(max-width: 1024px)' })

  const isTabletAndBelow = useIsLargeMediaQuery()
  return (
    <div id="roadmap" tw="relative w-full flex flex-col justify-center items-center">
      <div tw="flex flex-col w-full items-center py-20 px-6">
        <h2 tw="w-full text-center font-bold text-5xl mb-16 lg:mb-12">Roadmap</h2>
        <div tw='flex flex-col gap-12'>
          {
            phases.map((phase, index) => (
              <div
                key={index}
                tw='grid grid-cols-[36px 1fr] lg:grid-cols-[1fr 36px 1fr] lg:gap-14 '
              >
                {
                  (!isTabletAndBelow && index % 2 === 0) &&
                  <>
                    {/* Extra div required because safari is weird: https://stackoverflow.com/a/58730715 */}
                    <div>
                      <div tw="grid grid-rows-[auto 0px auto]">
                        <div tw="flex items-end pb-4 font-bold text-2xl overflow-hidden">
                          {phase.title}
                          {phase.complete && <span tw='text-toolbox-red'>&nbsp;- Complete</span>}
                        </div>
                        <div tw="w-[389px] border-b-[1px] border-[#243959] left-0 bottom-0"></div>
                        <div tw="flex flex-col gap-2 pt-4 overflow-hidden">
                          {
                            phase.bullets.map((subTitle, index) => (
                              <div key={index} tw="flex justify-start items-start gap-3">
                                <img alt={''} src={iconLiMarkSvg} tw="pt-[7px]" />
                                <span tw="text-lg max-w-[100px] sm:max-w-[305px] lg:max-w-[258px] leading-7">{subTitle}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                    <div tw="flex flex-col w-9 h-full mt-[30px]">
                      <img src={phase.iconImg} alt=""/>
                      <div tw='flex justify-center w-full h-full'>
                        {phases[index + 1] && phases[index + 1].complete
                          ? <div tw="border-l-[1px] border-l-toolbox-red left-[calc(50% - 1px)] top-[48px] h-[calc(100% + 48px)]"></div> :
                          phases[index + 1] != null
                          ? <div tw="border-l-[1px] border-dashed border-l-toolbox-red left-[calc(50% - 1px)] top-[48px] h-[calc(100% + 48px)]"></div>
                          : null
                        }
                      </div>
                    </div>
                    {!isTabletAndBelow && <div></div>}
                  </>
                }
                {
                  (isTabletAndBelow || index % 2 === 1) &&
                  <>
                    {!isTabletAndBelow && <div></div>}
                    <div tw="flex flex-col w-9 h-full mt-[30px]">
                      <img src={phase.iconImg} alt=""/>
                      <div tw='flex justify-center w-full h-full'>
                        {phases[index + 1] && phases[index + 1].complete
                          ? <div tw="border-l-[1px] border-l-toolbox-red left-[calc(50% - 1px)] top-[48px] h-[calc(100% + 48px)]"></div> :
                          phases[index + 1] != null
                          ? <div tw="border-l-[1px] border-dashed border-l-toolbox-red left-[calc(50% - 1px)] top-[48px] h-[calc(100% + 48px)]"></div>
                          : null
                        }
                      </div>
                    </div>
                    {/* Extra div required because safari is weird: https://stackoverflow.com/a/58730715 */}
                    <div>
                      <div tw="grid grid-rows-[auto 0px auto]">
                        <div tw="flex items-end pb-4 pl-8 lg:pl-16 font-bold text-2xl">
                          {phase.title}
                          {phase.complete && <span tw='text-toolbox-red'>&nbsp;- Complete</span>}
                        </div>
                        <div tw="w-[320px] sm:w-[389px] h-0 border-b-[1px] border-[#243959]"></div>
                        <div tw="flex flex-col gap-2 pt-4 pl-8 lg:pl-16">
                          {
                            phase.bullets.map((subTitle, index) => (
                              <div key={index} tw="flex justify-start items-start gap-3">
                                <img alt={''} src={iconLiMarkSvg} tw="pt-[7px]" />
                                <span tw="text-lg max-w-[290px] sm:max-w-[305px] lg:max-w-[258px] leading-7">{subTitle}</span>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </>
                }

              </div>
            ))
            }
          </div>
      </div>
      <div tw='mb-28 mt-[200px] sm:mt-0'>
        <a
          href="https://www.youtube.com/c/DIYWithMichaelBorders/videos"
          target="_blank"
          rel="noreferrer"
          tw="flex items-center font-bold h-14 align-middle px-[30px] py-[10px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] bg-toolbox-red rounded-full text-lg"
        >
          Subscribe now
        </a>
      </div>
      </div>
  );
};

export default RoadMapSection;

import 'twin.macro';
import CoinsImage from '../../assets/svgs/staking/staking-coins.svg';

const StakingFooter = () => {
  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] mt-32'>
      <div id="staking-footer" tw="relative grid grid-cols-1 lg:grid-cols-2 w-full gap-6">
        <div tw='flex flex-col items-center w-full'>
          <h1 tw='text-[48px] font-bold'>What is staking, and how does it work?</h1>
          <p tw='text-lg leading-8 text-[#A7B0CA]'>
            LP stands for "liquidity provider", and therefore an LP token serves as proof that you as a user are contributing to the liquidity of the Toolbox project. We DIYers need to stick together and of course we want to give back to all people who contribute to the health and wellbeing of the project! This is why all LP token holders can stake their tokens on this page and receive Socket tokens in return! Everybody wins!
          </p>
        </div>
        <div>
          <img src={CoinsImage} alt="A stack of LP tokens" />
        </div>
      </div>
    </div>
  );
};

export default StakingFooter;

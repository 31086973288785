import { createMachine } from 'xstate';

export const buyRatchetMachine =
/** @xstate-layout N4IgpgJg5mDOIC5QCMCuBPAtAJwIYBcBjACzHwDoBLCAGzAGIBBABWYCUB5ANQFEB9AMIAZAJICA0jwAiAbQAMAXUSgADgHtYlfJTUA7ZSAAeiAEwBGABwBWcnLkB2AJwnHcq1ftmAbABYANCDoiBYAzLZ2dj52jmauJiEAvgkBaFh4RKQUuCoq2GoAbpS6UEysnLx8LOzc0nwiAHJ8ALI8ACqMTYwAyuLySkgg6praegbGCCGW5GZmnjE+PhYmAUEI5ibTXluOk15WllYmFkkpGDgEJGTk2bkFRSVV5fwCHE3MQm08fQZDWjr6A3Gkws01msTMCyWK0QIRCGxi2xCjl8O3sXhOIFS5wyVxueUKxXoPDYnDY3wGvxGANAQKmMzmEMWy0CiB8SPICK8FjM+whsWOyUxZ3SlyyOXxYAAYkVcDRKAAve6laoVF5vD6tL6KH4aP6jQHBEIgqzWZFeM0hexOaFrMwbbyI7z7axHDFYkWZa7igpSmVyxWE4mk8mqXVUsaILlyaYLRw+c1WkLxqw2o2OWyWnz2JPOHyOZxu4UXT0qVDYEi4TSE5gAVTYAgAEt1+I8alI6o0Wu1Oj0Q4Mw-8IwgzJN01ZUY4rA5DmYHDbzGEInITCjrJZ0YL3cWrqXy8RK0qgxwydqKQP9TTEBDfOQVxY5JZHBYLItn6mPByPPZuXIvE45CEv6Fmk24ULuFawL6uiygqSq1vWTZdM8rzvJ8faUoOBrDhYXjRnI8bIlYXgmFEeZePOI7hHYK55muOHAdiorkOB+6QdK0H+oeJLHuh57UkYV7bOQeZOFyU4mHYvipjM5CHEm45WD4RwLFYDEelcEB6AwhiwPgBBgNcABm+BgNgAAUU52AAlPQW44hQmm6GAvHDJhl62tY4ROC4bgeN4-gsraGwmJZRxTp4JFeIkm5FvZ5CmXk2D0DpekmUZJnmZZcg2XZTEJWo2AuXq-HjOueEEXsxGkciqawuQ9ihfYLhLPYchxkkgq6GoEBwAYuWZDqrkXgJCCYORgVjVRdiKSay6TiOamgVQtBgINxVDvErjCZauEzUuIQUTYIV2CEViwuOAFLItcV4ncxRreGWFKTaoTkNYEQmCRI7OHC11Mbd+RQTBAZQA9bkjZV5BIi+TVLDyO0UWYHIOvY8YWKjZ32H9JZlhB9xg8NpW-kj46WvNEkji484AfVWWLHIFhxiu2M7rjrFA5x91nkNJWCeat4zA+RoSejk4URsDURPeEnfq1Jgsw5WkE7zaxtT4UOuNmMxTiOHji7TUvLg46PLgr8XYIlytDrMRyyVyJio8+TgmgdgWHPalhJjbrizljHVAA */
createMachine({
  context: {},
  tsTypes: {} as import('./buy-ratchet-machine.typegen').Typegen0,
  schema: {
    context: {},
    events: {} as
      | { type: 'APPROVE_CLICKED' }
      | { type: 'APPROVE_APPROVED_IN_METAMASK' }
      | { type: 'APPROVE_COMPLETE' }
      | { type: 'PURCHASE_APPROVED_IN_METAMASK' }
      | { type: 'PURCHASE_COMPLETE' }
      | { type: 'ERROR' },
  },
  predictableActionArguments: true,
  id: 'buy-ratchet',
  initial: 'idle',
  states: {
    idle: {
      on: {
        APPROVE_CLICKED: {
          target: 'approving',
        },
      },
    },
    approving: {
      entry: 'approveInMetamask',
      on: {
        APPROVE_APPROVED_IN_METAMASK: {
          target: 'approveFinalizing',
        },
        APPROVE_COMPLETE: {
          target: 'purchasing',
        },
        ERROR: {
          target: 'error',
          actions: 'approveError',
        },
      },
    },
    approveFinalizing: {
      on: {
        APPROVE_COMPLETE: {
          target: 'purchasing',
        },
        ERROR: {
          target: 'error',
          actions: 'approveError',
        },
      },
    },
    purchasing: {
      entry: 'purchaseInMetamask',
      on: {
        PURCHASE_APPROVED_IN_METAMASK: {
          target: 'purchaseFinalizing',
        },
        ERROR: {
          target: 'error',
          actions: 'purchaseError',
        },
      },
    },
    purchaseFinalizing: {
      on: {
        PURCHASE_COMPLETE: {
          target: 'done',
        },
        ERROR: {
          target: 'error',
          actions: 'purchaseError',
        },
      },
    },
    done: {
      after: {
        '5000': {
          target: '#buy-ratchet.idle',
          actions: [],
          internal: false,
        },
      },
    },
    error: {
      entry: (_ctx, event, state) => {
        console.error(
          `There was an error: state=${state.state}, event=${event.type}`
        );
      },
      after: {
        '5000': {
          target: '#buy-ratchet.idle',
          actions: [],
          internal: false,
        },
      },
    },
  },
});

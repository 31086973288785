import { ethers, FixedNumber } from 'ethers';
import 'twin.macro';
import { useStaker } from '../../hooks/useStaker';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Loader from '../../components/Loader';

const YourStakingSection = () => {

  const { claimRewards, refresh, rewardsOwed } = useStaker()
  const [loading, setLoading] = useState(false)

  const rewardsOwedFormatted = (+ethers.utils.formatEther(rewardsOwed)).toFixed(4)

  return (
    <div id="stake"tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] mt-32'>
      <div tw="relative grid grid-cols-1 justify-center items-center w-full gap-6">
        <div tw="flex flex-col h-full items-center bg-[#18273E] p-[50px] px-8 md:px-12 lg:px-[140px] xl:px-[240px] gap-6 rounded-2xl">
          <div tw='font-bold text-[32px] text-center'>
            Your SOCKET Rewards
          </div>
          <div tw="flex bg-toolbox-navy w-full justify-between items-center gap-6 py-4 px-4 md:py-6 md:px-8 rounded-2xl">
            <div tw='w-10'>
              {/* Empty for spacing */}
            </div>
            <div tw="font-extralight text-5xl text-white overflow-hidden overflow-ellipsis">
              {rewardsOwedFormatted}
            </div>
            <div tw='w-10'>
              <IconButton onClick={refresh}>
                <RefreshIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
          </div>
          <div>
            <p tw='text-center'>
              <span tw='font-bold text-yellow-200'>Note: </span> This value only updates when you unstake an LP token. <br/>
              You can see the accrued rewards for individual tokens listed above.
            </p>
          </div>
          <div tw='flex flex-col gap-8 font-extralight text-[#A7B0CA] text-2xl mb-[18px]'>
            <p>
              The more liquidity that you provide to the Toolbox/ETH pool, the more rewards you will accumulate!
              For every second that your LP token is staked, you will earn a portion of the incentive pool of
              Socket tokens. The amount you will earn is proportional to your share of the total liquidity in the pool.
            </p>
            <p>
              Thank you for your support in providing liquidity to the DIY/ETH pool, and please enjoy the
              rewards made possible by your staking!
            </p>
          </div>
          <div>
            <button
              tw='flex items-center w-[236px] py-[14px] justify-center font-extrabold text-lg align-middle px-[30px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] disabled:shadow-none bg-toolbox-red disabled:bg-background-navy rounded-full disabled:cursor-not-allowed'
              disabled={loading || rewardsOwed.isZero()}
              onClick={async () => {
                setLoading(true)
                await claimRewards().catch(() => {})
                await refresh()
                setLoading(false)
              }}
            >
              <div tw='flex gap-2'>
                Collect reward
                {
                  loading && <Loader />
                }
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourStakingSection;

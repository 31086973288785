import 'twin.macro';

import { Link } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';


export const socialLink = [
  { icon: 'instagram', link: 'https://www.instagram.com/diywithmike/', title: 'Instagram' },
  { icon: 'twitter', link: 'https://twitter.com/toolboxtoken/', title: 'Twitter' },
  { icon: 'youtube', link: 'https://www.youtube.com/c/DIYWithMichaelBorders/', title: 'Youtube' },
];

const Footer = () => {
  return (
    <footer id="footer" tw="w-full py-16 flex flex-col justify-center items-center gap-8">
      <div tw="font-generatorUltraBold text-2xl">Stay in the Loop</div>
      <div tw='flex flex-col gap-4'>
        <div>
          <ul tw="flex gap-8">
            {socialLink.map((item) => (
              <li
                key={item.title}
                tw="rounded-full"
              >
                <SocialIcon fgColor="white" bgColor='#ED1C24' target="_blank" network={item.icon} style={{ height: 48, width: 48 }} url={item.link} />
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul tw="flex justify-evenly ">
            <li>
              <div tw="flex flex-col justify-center items-center gap-1 relative">
                <SocialIcon
                  url='mailto:rex@topflightapps.com'
                  fgColor="white"
                  bgColor='#ED1C24'
                  label='Email Developers'
                />
                <a href="mailto:rex@topflightapps.com">Devs</a>
              </div>
            </li>
            <li>
              <div tw="flex flex-col justify-center items-center gap-1 relative">
                <SocialIcon
                  url='mailto:rex@topflightapps.com'
                  fgColor="white"
                  bgColor='#ED1C24'
                  label='Email Team'
                />
                <a href="mailto:rex@topflightapps.com">Team</a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { IconButton } from '@mui/material';
import 'twin.macro';
import { useNftPositions } from '../../hooks/useNftPositions';
import { useStakedNftPositions } from '../../hooks/useStakedNftPositions';
import { NftPositionCard } from './NftPositionCard';
import RefreshIcon from '@mui/icons-material/Refresh'

const YourLpTokensSection = () => {

  const { nftPositions: unstakedNftPositions, refresh: refreshNftPositions } = useNftPositions()
  const { nftPositions: stakedNftPositions, refresh: refreshStakedPositions } = useStakedNftPositions()

  const refreshAll = () => {
    refreshNftPositions()
    refreshStakedPositions()
  }

  return (
    <div id="staking-stats" tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%] mt-[-100px]'>
      <div tw="relative flex flex-col justify-center items-center w-full">
        {(unstakedNftPositions?.length ?? 0) > 0 &&
          <div tw="flex flex-col w-[100vw] md:w-full bg-[#18273E] items-center px-8 md:px-12 lg:px-[140px] xl:px-[240px] py-[76px] lg:py-12 gap-6 rounded-2xl">
            <div tw='flex'>
              <div tw="text-4xl font-bold text-center">
                Your LP Tokens
              </div>
              <IconButton onClick={refreshAll}>
                <RefreshIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
            <div tw="text-lg text-center leading-8 text-[#A7B0CA]">
              Stake your Liquidity Pool tokens to earn extra Socket tokens.<br />
              This will deposit and stake them into the staking contract. You can unstake whenever you like and the NFT will be returned to your wallet.
            </div>
            <div tw='flex flex-wrap justify-center gap-8'>
              {unstakedNftPositions?.map(nftPosition => (
                <NftPositionCard
                  nftPosition={nftPosition}
                  type='unstaked'
                  onToggleStakeDone={refreshAll}
                  key={nftPosition.nftId}
                />
              ))}
            </div>
          </div>
        }
        {(stakedNftPositions?.length ?? 0) > 0 &&
          <div tw="flex flex-col w-[100vw] md:w-full bg-[#18273E] items-center px-8 md:px-12 lg:px-[140px] xl:px-[240px] py-[76px] lg:py-12 gap-6 rounded-2xl">
            <div tw='flex'>
              <div tw="text-4xl font-bold text-center">
                Your Staked LP Tokens
              </div>
              <IconButton onClick={refreshAll}>
                <RefreshIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
            <div tw="text-lg text-center leading-8 text-[#A7B0CA]">
              If you unstake an LP token, it will be returned to your wallet and it will stop earning rewards.<br />
              That token's accrued rewards will then be available to claim below.
            </div>
            <div tw='flex flex-wrap justify-center gap-8'>
              {stakedNftPositions?.map(nftPosition =>
                <NftPositionCard
                  nftPosition={nftPosition}
                  type='staked'
                  onToggleStakeDone={refreshAll}
                  key={nftPosition.nftId}
                />
              )}
            </div>
          </div>
        }

      </div>
    </div>
  );
};

export default YourLpTokensSection;

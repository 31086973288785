import { convertNftIdToNftPosition } from './../types/NftPosition';
import { useWeb3Provider } from './useWeb3Provider';
import { useEffect, useState } from 'react';
import { CONTRACTS } from '../constants/contracts'
import { INonfungiblePositionManager, IUniswapV3Staker } from '../types/typechain-types/contracts/interfaces'
import { useContract } from './useContract'
import { NftPosition } from '../types/NftPosition';
import { envVars } from '../constants/env';

export const useStakedNftPositions = () => {

  const { account: wallet } = useWeb3Provider()
  const [nftPositions, setNftPositions] = useState<NftPosition[] | null>(null)
  const [trigger, setTrigger] = useState(0)

  const refresh = () => setTrigger(trigger + 1)

  const stakerContract = useContract<IUniswapV3Staker>(
    CONTRACTS.UniswapV3Staker.address,
    CONTRACTS.UniswapV3Staker.abi,
    true
  )

  const nftPositionManagerContract = useContract<INonfungiblePositionManager>(
    CONTRACTS.NonfungiblePositionManager.address,
    CONTRACTS.NonfungiblePositionManager.abi,
    true
  )

  useEffect(() => {
    if (stakerContract == null) { return }
    if (wallet == null) { return }
    if (nftPositionManagerContract == null) { return }

    const getStakes = async () => {
      const filter = stakerContract.filters.DepositTransferred(null, null, wallet)
      const res = await stakerContract.queryFilter(filter)
      const uniqueTokenIds = Array.from(new Set(res.map(e => e.args.tokenId.toNumber())))
      const nftPositions = await Promise.all(
        uniqueTokenIds.map(id =>
          convertNftIdToNftPosition(id, nftPositionManagerContract)
        )
      )
      const maybeStakedNftPositions = await Promise.all(
        nftPositions
          .filter(p => p.owner === CONTRACTS.UniswapV3Staker.address)
          .filter(p => !p.liquidity.isZero())
          .map(async p => {
            const { reward } = await stakerContract.callStatic.getRewardInfo(
              envVars.INCENTIVE_KEY,
              p.nftId
            ).catch(() => ({ reward: null }))
            if (reward == null) { return null }
            p.accruedRewards = reward
            return p
          })
      )
      const stakedNftPositions = maybeStakedNftPositions.filter((p): p is NftPosition => p != null)
      setNftPositions(stakedNftPositions)
    }
    getStakes()
  }, [stakerContract, wallet, nftPositionManagerContract, trigger])

  return { nftPositions, refresh }
}
import 'twin.macro';

import { FC, useState } from 'react';
import { CONTRACTS } from '../../constants/contracts';
import { useContract } from '../../hooks';
import { depositAndStakeNft, NftPosition, unstakeAndWithdrawNft } from '../../types/NftPosition';
import { INonfungiblePositionManager, IUniswapV3Staker } from '../../types/typechain-types/contracts/interfaces';
import Loader from '../../components/Loader';
import { ethers } from 'ethers';
import { triggerToast } from '../../utils';
import { envVars } from '../../constants/env';

type NftPositionCardProps = {
  nftPosition: NftPosition,
  type: 'unstaked' | 'staked',
  onToggleStakeClicked?: () => void,
  onToggleStakeDone?: () => void,
}

export const NftPositionCard: FC<NftPositionCardProps> = props => {
  const { nftPosition, onToggleStakeClicked, onToggleStakeDone, type } = props

  const [loading, setLoading] = useState(false)

  const stakerContract = useContract<IUniswapV3Staker>(
    CONTRACTS.UniswapV3Staker.address,
    CONTRACTS.UniswapV3Staker.abi,
    true
  )

  const nftPositionManagerContract = useContract<INonfungiblePositionManager>(
    CONTRACTS.NonfungiblePositionManager.address,
    CONTRACTS.NonfungiblePositionManager.abi,
    true
  )

  return (
    <div>
      <div tw='flex flex-col items-center justify-start gap-6'>
        {
          nftPosition.svg != null &&
          <div tw='h-[350px]'>
            <img
              src={nftPosition.svg.image}
              alt={nftPosition.svg.name}
              css={{ maxHeight: '100%' }}
            />
          </div>
        }
        <button
          tw='flex items-center w-[206px] py-[14px] justify-center font-extrabold text-lg align-middle px-[30px] duration-200 hover:shadow-[0px 0px 50px -10px #ED1C24] disabled:shadow-none bg-toolbox-red disabled:bg-background-navy rounded-full disabled:cursor-not-allowed'
          disabled={loading}
          onClick={async () => {
            try {
              setLoading(true)
              onToggleStakeClicked?.()
              if (type === 'unstaked') {
                if (nftPositionManagerContract == null) { return }
                await depositAndStakeNft(nftPosition.nftId, nftPositionManagerContract)
                  .catch(e => {
                    try {
                      if (e.error.message.includes('incentive not started')) {
                        triggerToast('ERROR', `Incentive does not start until ${new Date(Number(envVars.INCENTIVE_KEY.startTime) * 1000)}`)
                      }
                    } catch {
                      throw e
                    }
                  })
              } else /* type === staked */ {
                if (stakerContract == null) { return }
                await unstakeAndWithdrawNft(nftPosition.nftId, stakerContract)
              }
              onToggleStakeDone?.()
            } finally {
              setLoading(false)
            }
          }}
        >
          <div tw='flex gap-2'>
            {
              type === 'unstaked'
                ? 'Stake'
                : 'Unstake'
            }
            {
              loading && <Loader />
            }
          </div>
        </button>
        {
          type === 'staked' && nftPosition.accruedRewards != null &&
          <div>
            Accrued Rewards: {(+ethers.utils.formatEther(nftPosition.accruedRewards)).toFixed(4)}
          </div>
        }
      </div>
    </div>
  )
}
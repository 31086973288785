import 'twin.macro';

// const whitepaperLink = 'https://docs.google.com/document/d/1hKhpO7G7Y9Eexyi9SrM_sLesXxdoxPdMWOTpR9hFe7M/edit'
const whitepaperLink = 'https://toolbox-public-files.s3.amazonaws.com/toolbox-whitepaper.pdf'

const WhitepaperSection = () => {
  return (
    <div tw='px-[18px] sm:px-[50px] lg:px-[8%] xl:px-[10%]'>
    <div tw="relative w-full mt-8">
      <div tw="flex justify-center items-center text-center min-h-[60px] bg-toolbox-navy rounded-lg p-4">
        <p tw="text-lg">
          The token for the DIY community.
          Every single one of us has a little DIY in our blood.
          See our
          &nbsp;
          <a
            href={whitepaperLink}
            tw="text-toolbox-red hover:text-[#ec7593] duration-300 ease underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            whitepaper
          </a>
          &nbsp;
          here
        </p>
      </div>
    </div>
    </div>
  );
};

export default WhitepaperSection;

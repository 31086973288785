import { IUniswapV3Staker } from './typechain-types/contracts/interfaces/IUniswapV3Staker';
import { BigNumber, BigNumberish, Contract, ethers } from 'ethers'
import { CONTRACTS } from '../constants/contracts'
import { INonfungiblePositionManager } from './typechain-types/contracts/interfaces'
import { envVars } from '../constants/env';

export type NftPositionSvg = {
  name?: string
  description?: string
  image?: string
}

export type NftPosition = {
  nftId: number,
  nonce: BigNumber,
  operator: string,
  token0: string,
  token1: string,
  fee: number,
  tickLower: number,
  tickUpper: number,
  liquidity: BigNumber,
  feeGrowthInside0LastX128: BigNumber,
  feeGrowthInside1LastX128: BigNumber,
  tokensOwed0: BigNumber,
  tokensOwed1: BigNumber,
  svg: NftPositionSvg | null,
  owner: string | null,
  accruedRewards: BigNumber | null
}

export const convertNftIdToNftPosition = async (
  nftId: number,
  nftPositionManagerContract: INonfungiblePositionManager,
): Promise<NftPosition> => {
  const position = await nftPositionManagerContract.positions(nftId)
  // Get the token's SVG
  let svg: NftPositionSvg | null = null
  try {
    const tokenUri = await nftPositionManagerContract.tokenURI(nftId)
    const [_encoding, encodedData] = tokenUri.split(',')
    const nftData = JSON.parse(Buffer.from(encodedData, 'base64').toString())
    if (nftData != null && typeof nftData === 'object') {
      svg = nftData
    } else {
      console.error('Unable to get the NFT SVG')
    }
  }
  catch {
    console.error('Unable to get the NFT SVG')
  }

  // Get the token owner's address
  let owner: string | null = null
  try {
    owner = await nftPositionManagerContract.ownerOf(nftId)
  } catch {
    console.error('Unable to get the NFT SVG')
  }
  /* eslint-disable sort-destructure-keys/sort-destructure-keys */
  const {
    nonce,
    operator,
    token0,
    token1,
    fee,
    tickLower,
    tickUpper,
    liquidity,
    feeGrowthInside0LastX128,
    feeGrowthInside1LastX128,
    tokensOwed0,
    tokensOwed1
  } = position
  return {
    nftId,
    nonce,
    operator,
    token0,
    token1,
    fee,
    tickLower,
    tickUpper,
    liquidity,
    feeGrowthInside0LastX128,
    feeGrowthInside1LastX128,
    tokensOwed0,
    tokensOwed1,
    svg,
    owner,
    accruedRewards: null
  }
  /* eslint-enable sort-destructure-keys/sort-destructure-keys */

}

export type IncentiveKeyStruct = {
  rewardToken: string;
  pool: string;
  startTime: BigNumberish;
  endTime: BigNumberish;
  refundee: string;
};

const INCENTIVE_KEY_ABI =
'tuple(address rewardToken, address pool, uint256 startTime, uint256 endTime, address refundee)'

export const depositAndStakeNft = async (nftId: number, nftPositionManager: INonfungiblePositionManager) => {
  // const incentiveId = await getIncentiveId()
  // const incentiveIdBytes = ethers.utils.arrayify(incentiveId)
  const data = ethers.utils.defaultAbiCoder.encode(
    [INCENTIVE_KEY_ABI],
    [envVars.INCENTIVE_KEY]
  )
  console.log(data)
  const ownerAddress = await nftPositionManager.signer.getAddress()
  const depositedAndStaked = await nftPositionManager['safeTransferFrom(address,address,uint256,bytes)'](
    ownerAddress,
    CONTRACTS.UniswapV3Staker.address,
    nftId,
    data
  )
  await depositedAndStaked.wait()
}

const encodeUnstakeFn = (stakerContract: Contract, incentiveTuple: any[], tokenId: number) =>
  stakerContract.interface.encodeFunctionData(
    'unstakeToken',
    [incentiveTuple, tokenId]
  )

const encodeWithdrawFn = (stakerContract: Contract, tokenId: number, account: string) =>
  stakerContract.interface.encodeFunctionData(
    'withdrawToken',
    [tokenId, account, []]
  )

export const unstakeAndWithdrawNft = async (nftId: number, uniswapStaker: IUniswapV3Staker) => {
  const ownerAddress = await uniswapStaker.signer.getAddress()
  const calls = [
    ethers.utils.arrayify(encodeUnstakeFn(uniswapStaker, envVars.INCENTIVE_TUPLE, nftId)),
    ethers.utils.arrayify(encodeWithdrawFn(uniswapStaker, nftId, ownerAddress))
  ];
  const withdrawn = await uniswapStaker.multicall(calls)
  console.log('Withdraw done')
  await withdrawn.wait()
  console.log('Withdraw waited')
}
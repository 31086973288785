import 'twin.macro';
import RatchetAsset from '../assets/images/ecosystem/coin-ratchet-red.png'
import SocketAsset from '../assets/images/ecosystem/coin-socket-red.jpg'
import ToolboxAsset from '../assets/images/ecosystem/coin-toolbox-red.jpg'

const Assets = () => {
  return (
    <div tw='grid grid-cols-3 mt-[84px] gap-16 py-16 bg-white'>
      <img src={ToolboxAsset} />
      <img src={RatchetAsset} />
      <img src={SocketAsset} />
    </div>
  );
};

export default Assets;

import 'twin.macro';

import { Link } from 'react-router-dom';

import imgLogo from '../assets/images/logo.svg';
import iconMenu from '../assets/svgs/sandwich.svg';
import iconClose from '../assets/svgs/times.svg';
import ConnectWalletButton from './ConnectWalletButton';

export const navMenu = [
  { isExternal: false, link: '/#about', title: 'About' },
  { isExternal: false, link: '/#guide', title: 'Guide' },
  { isExternal: false, link: '/#roadmap', title: 'Roadmap' },
  { isExternal: false, link: '/staking', title: 'Staking' },
  { isExternal: false, link: '/assets', title: 'Assets' },
  { isExternal: true, link: 'https://www.youtube.com/c/DIYWithMichaelBorders', title: 'YouTube' },
];

const Header = ({
  menuOpened,
  onToggleMenu,
}: {
  menuOpened: boolean;
  onToggleMenu: () => void;
}) => {
  return (
    <>
      <header tw="w-full h-[84px] fixed bg-background-navy/75 backdrop-blur-lg flex font-bold justify-center top-0 z-20">
        <div tw="w-full flex justify-between items-center mx-[20px] lg:mx-[75px]">
          <div tw="flex justify-center items-center max-w-[202px] h-[40%] mr-8">
          <a href="/" tw='h-full'>
              <img alt="logo" src={imgLogo} tw='max-h-full' />
          </a>
          </div>
          <div tw="hidden h-full lg:flex items-center gap-8">
            <ul tw="flex items-center gap-8 h-full">
              {navMenu.map((item) => (
                <li key={item.title}>
                  <a href={item.link} rel="noreferrer" target={item.isExternal ? '_blank' : '_self'} tw="capitalize hover:underline">
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <div tw=" flex items-center ml-4 h-full">
              <ConnectWalletButton />
            </div>
          </div>
          <Link to="#" tw="lg:hidden" onClick={() => onToggleMenu()}>
            <img alt="menu" src={menuOpened ? iconClose : iconMenu} />
          </Link>
        </div>
      </header>
    </>
  );
};

export default Header;

import { ERC20 } from './../types/typechain-types/@openzeppelin/contracts/token/ERC20/ERC20';
import { useState } from 'react';

export const useTokenBalance = (contract?: ERC20 | null, address?: string | null) => {
  const [balance, setBalance] = useState(0)

  const refresh = async () => {
    if (address == null) {
      console.error('Address was null')
      return
    }
    if (contract == null) {
      console.error('Contract was null')
      return
    }
    const newBalance = await contract.balanceOf(address).then(balance => balance.toNumber()).catch(e => {
      console.error(e)
      return 0
    })
    setBalance(newBalance)
  }

  refresh()

  return { balance, refresh }
}